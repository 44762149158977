import ScrollReveal from "scrollreveal";

//scrollreveal
ScrollReveal().reveal(".fade-in-bottom", {
  origin: "bottom",
  distance: "30px",
  easing: "cubic-bezier(.6,.26,.35,.74)",
  duration: 700,
});

var fadeinBottom = {
  origin: 'bottom',
  distance: '40px',
  easing: 'ease-in-out',
  duration: 500,
  delay: 400
};
var fadeinBottomInterval = {
  origin: 'bottom',
  distance: '40px',
  easing: 'ease-in-out',
  duration: 500,
  interval: 200,
  delay: 400
};

ScrollReveal().reveal('.fadeinbottom', fadeinBottom);
ScrollReveal().reveal('.fadeinbottominterval', fadeinBottomInterval);