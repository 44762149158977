import $ from "jquery";
import "what-input";
import libs from "./lib/dependencies.js";

window.$ = $;
window.jQuery = $;
//window.libs = libs;

//import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import 'foundation-sites'
import "./lib/foundation-explicit-pieces.js";

//Pixmill Scripts
import { ready } from "./px/utils.js";

import "./px/hamburger.js";
import "./px/arrowdown.js";
import "./px/opaque.js";
import "./px/form.js";
import "./px/lazyload.js";
import "./px/scrollreveal.js";
import "./px/datatable.js";
import "./px/slickslider.js";
//import { addFunctionalityToProgressPath } from "./px/scrollProgress.js";

//ready(function () {
//  addFunctionalityToProgressPath("progress-wrap");
//});




//import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//require('foundation-sites');
import './lib/foundation-explicit-pieces.js';

$(document).foundation();
