import $ from "jquery";
//import DataTable from 'datatables.net-dt';
//<script src="https://cdn.datatables.net/1.10.20/js/jquery.dataTables.min.js"></script>



//immo
$(document).ready(function($){

    $('tr.immodata').on('click',  function(){
        var datanr = $(this).find('td:nth-child(2)').text();
        var datanr = datanr.replace("*","");
        $("img.whgimage").attr("src","assets/Dateien/immo/iso/Konstanz_Nord_Isometrie_"+datanr+".svg");

    })
});
/*
$(document).ready(function() {
    $('#whgnA').DataTable({
        "searching": false,
        "paging":   false,
        "info":     false,
        "order": [ [ 0, 'asc' ] ]
    });
    $('#whgnB').DataTable({
        "searching": false,
        "paging":   false,
        "info":     false,
        "order": [ [ 0, 'asc' ] ]
    });
});

*/