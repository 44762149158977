import $ from 'jquery';
import 'slick-carousel';

$(".slider").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    nextArrow: '<button class="arrow-next"><div><img src="assets/app/images/ArrowNext-1.svg" width="58" height="58"></div><span></span></button>',
    responsive: [{
        breakpoint: 1600,
        settings: {
            slidesToShow: 2
        }

    }, {
        breakpoint: 600,
        settings: {
            slidesToShow: 1
        }
    }, {
        breakpoint: 200,
        settings: "unslick" // destroys slick
    }]
});